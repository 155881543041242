
import {defineComponent, ref, Ref, PropType} from 'vue';
import useStatus from './useStatus'

export default defineComponent({
  name: "Items",
  props: {
    server: {
      type: Object as PropType<StatusItem | BoxItem>,
      default: {}
    }
  },
  setup(props) {
    const collapsed: Ref<boolean> = ref(true);
    const utils = useStatus(props);
    return {
      collapsed,
      ...utils
    }
  }
})
