
import {defineComponent, PropType} from 'vue';
import CardItem from '@/components/CardItem.vue';

export default defineComponent({
  name: "Card",
  props: {
    servers: Array as PropType<Array<StatusItem | BoxItem>>
  },
  components: {
    CardItem
  }
})
