
import {defineComponent, PropType, ref, Ref} from 'vue';
import useStatus from './useStatus'

export default defineComponent({
  name: "CardItem",
  props: {
    server: {
      type: Object as PropType<StatusItem | BoxItem>,
      default: {}
    }
  },
  setup(props) {
    const collapsed: Ref<boolean> = ref(true);
    const {getStatus, getRAMStatus, tableRowByteConvert} = useStatus(props);
    return {
      collapsed,
      getStatus,
      getRAMStatus,
      tableRowByteConvert
    }
  }
})
