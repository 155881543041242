
import {defineComponent, ref, Ref, onMounted} from 'vue';
import axios from 'axios'
import Header from '@/components/Header.vue';
import Error from '@/components/Error.vue';
import Body from '@/components/Body.vue';
import UpdateTime from '@/components/UpdateTime.vue';
import Card from '@/components/Card.vue'
import Footer from '@/components/Footer.vue'

export default defineComponent({
  name: 'App',
  components: {
    Header,
    Error,
    Body,
    Card,
    Footer,
    UpdateTime
  },
  setup() {
    const servers: Ref<Array<StatusItem | BoxItem> | undefined> = ref();
    const updated: Ref<number | undefined> = ref();
    onMounted(() => {
      setInterval(() => {
        axios.get('json/stats.json')
            .then(res => {
              servers.value = res.data.servers;
              updated.value = Number(res.data.updated);
            })
            .catch((err) => console.log(err));
      }, 2000);
    });
    return {
      servers,
      updated
    }
  }
})
