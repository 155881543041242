
import {defineComponent, PropType} from 'vue';
import Items from '@/components/Items.vue';

export default defineComponent({
  name: "Body",
  props: {
    servers: {
      type: Array as PropType<Array<StatusItem | BoxItem>>,
      default: {}
    }
  },
  components: {
    Items
  }
})
